import React from 'react'
import "./mobile.css"
import chemelon from "./images/prospecter.png"
function Prospecter() {
  return (
    <div className='Mobilechemeleon'>

        <div className='textarea_mobile'>
            <span className='smalltitle_blue'>
                Product
            </span>
            <div className='larger_title'>
            Deep dive
            </div>
            <p className='mobiledesc_chemelon'>
At Freight Insurance Data, our deep dive solutions deliver the utmost in advanced and comprehensive analysis. We aggregate data from hundreds of sources, providing a user-friendly, one-stop platform for comprehensive carrier information. With interactive features and a focus on highlighting crucial details, our platform empowers you to make informed decisions.             </p>
            <div className='mobile_image_section'>
                <img src={chemelon} alt='image'></img>
            </div>
        </div>
    </div>
  )
}

export default Prospecter

import React from 'react'
import "./mobile.css"
import chemelon from "./images/Leads (2).png"
function Leads() {
  return (
    <div className='Mobilechemeleon'>

        <div className='textarea_mobile'>
            <span className='smalltitle_blue'>
                Product
            </span>
            <div className='larger_title'>
            Leads Database
            </div>
            <p className='mobiledesc_chemelon'>
Our proprietary lead data feature can help you close hundreds of additional leads annually. Filter transportation companies to match your criteria and appetite precisely. Access the industry's cutting-edge leads generator for an up-to-date list of motor carriers perfectly aligned with your operational, safety, and geographic preferences.            </p>
            <div className='mobile_image_section'>
                <img src={chemelon} alt='image'></img>
            </div>
        </div>
    </div>
  )
}

export default Leads

import React from 'react'
import Navbar from './Navbar'
import AboutusHero from './AboutusHero'
import Counter from './Counter'
import Standerdlorem from './Standerdlorem'
import FaqHero from './FaqHero'

function Faquestion() {
  return (
   <>
  <>
  <div className='coverforpagefaq abouthero'>
  <div className='Herosection'>
  <Navbar/>
  <FaqHero/>
 
  </div>
  </div>
  </>
   </>
  )
}

export default Faquestion;
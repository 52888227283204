import React from 'react'
import Navbar from '../component/Navbar'
import AboutusHero from '../component/AboutusHero'
import Counter from '../component/Counter'
import Standerdlorem from '../component/Standerdlorem'

function AboutUs() {
  return (
   <>
  <div className='abouthero'>
  <div className='Herosection'>
  <Navbar/>
  <AboutusHero/>
  <Counter/>
 
  </div>
  <div className='standersmartop'>
  <Standerdlorem/>
  </div>
  <div className="abovetofooter">

</div>
 
  </div>
   </>
  )
}

export default AboutUs
import './App.css';
import Home from './pages/Home';
import { Route, Routes } from 'react-router-dom';
import Footer from './component/Footer';
import AboutUs from './pages/AboutUs';
import Product from './pages/product/Product';
import Faq from './component/Faq';
import Faquestion from './component/Faquestion';
import Pricing from './component/Pricing';
import Chame from './pages/Chamelon/Chame';
import StackCamlion from './pages/Chamelon/StackCamlion';
import Allblogs from './pages/Blog/Allblogs';
import BlogDetail from './pages/Blog/BlogDetail';
import Started from './pages/getstarded/Getstarted';
import Getstarted from './pages/getstarded/Getstarted';
function App() {
 


  return (
    <>


      
<Routes>
  <Route path='/' element={<Home/>}/>
  <Route path='/aboutus' element={<AboutUs/>}/>
  <Route path='/registration' element={<Getstarted/>}/>
  <Route path='/products' element={<Product/>}/>
  <Route path='/faq' element={<Faquestion/>}/>
  <Route path='/pricing' element={<Pricing/>}/>
  <Route path='/chemlon' element={<Chame/>}/>
  <Route path='/chemelon' element={<StackCamlion/>}/>
  <Route path='/Blog-Detail' element={<BlogDetail/>}/>
  <Route path='/blogs' element={<Allblogs/>}/>
  

</Routes>

{window.location.pathname !== '/blogs' && window.location.pathname !== '/Blog-Detail' && window.location.pathname !== '/login' && <Footer/>}
    </>

  );
}

export default App;
import React from 'react'
import brand from "../../images/Brand.png"
import detailexplain from "./Images/detailblog.png"
import BlogCards from './BlogCards'
import Blogfooter from './Blogfooter'
function BlogDetail() {
  return (
    <>
    
  
    <div className='BlogDetail'>
         <div className='mainblogcon'>
         <div className='topnav'>
            <div className='gotohomeblog'>
          <img src={brand} alt='frieght logo'></img>  
          <span className='homeforblog'>Home</span>    
            </div>

<div className='navicons'>
<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
  <path d="M16 21C18.7614 21 21 18.7614 21 16C21 13.2386 18.7614 11 16 11C13.2386 11 11 13.2386 11 16C11 18.7614 13.2386 21 16 21Z" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
  <path d="M21.5 4.5H10.5C7.18629 4.5 4.5 7.18629 4.5 10.5V21.5C4.5 24.8137 7.18629 27.5 10.5 27.5H21.5C24.8137 27.5 27.5 24.8137 27.5 21.5V10.5C27.5 7.18629 24.8137 4.5 21.5 4.5Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M22.5 11C23.3284 11 24 10.3284 24 9.5C24 8.67157 23.3284 8 22.5 8C21.6716 8 21 8.67157 21 9.5C21 10.3284 21.6716 11 22.5 11Z" fill="white"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
  <path d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M21 11H19C18.6056 10.9984 18.2147 11.0748 17.85 11.225C17.4853 11.3752 17.1539 11.5961 16.875 11.875C16.5961 12.1539 16.3752 12.4853 16.225 12.85C16.0748 13.2147 15.9984 13.6056 16 14V28" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M12 18H20" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
  <path d="M20 16L14 12V20L20 16Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M3 15.9999C3 19.7249 3.3875 21.8999 3.675 23.0249C3.75427 23.3301 3.90418 23.6125 4.11262 23.8491C4.32106 24.0858 4.58221 24.2702 4.875 24.3874C9.0625 25.9874 16 25.9499 16 25.9499C16 25.9499 22.9375 25.9874 27.125 24.3874C27.4178 24.2702 27.6789 24.0858 27.8874 23.8491C28.0958 23.6125 28.2457 23.3301 28.325 23.0249C28.6125 21.8999 29 19.7249 29 15.9999C29 12.2749 28.6125 10.0999 28.325 8.97488C28.2457 8.66963 28.0958 8.38729 27.8874 8.15062C27.6789 7.91395 27.4178 7.72958 27.125 7.61238C22.9375 6.01238 16 6.04988 16 6.04988C16 6.04988 9.0625 6.01238 4.875 7.61238C4.58221 7.72958 4.32106 7.91395 4.11262 8.15062C3.90418 8.38729 3.75427 8.66963 3.675 8.97488C3.3875 10.0999 3 12.2749 3 15.9999Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
  <path d="M6 18C7.0515 17.9967 8.09327 18.2014 9.06537 18.6023C10.0375 19.0031 10.9207 19.5923 11.6642 20.3358C12.4077 21.0794 12.9969 21.9626 13.3978 22.9347C13.7986 23.9068 14.0033 24.9485 14 26" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M6 12C9.71303 12 13.274 13.475 15.8995 16.1005C18.525 18.726 20 22.287 20 26" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M6 6.00004C8.62689 5.9951 11.2287 6.51063 13.6553 7.51686C16.0818 8.52309 18.285 10.0001 20.1375 11.8625C22 13.7151 23.4769 15.9182 24.4832 18.3448C25.4894 20.7713 26.0049 23.3731 26 26" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M6.5 27C7.32843 27 8 26.3284 8 25.5C8 24.6716 7.32843 24 6.5 24C5.67157 24 5 24.6716 5 25.5C5 26.3284 5.67157 27 6.5 27Z" fill="white"/>
</svg>
</div>
          </div>
          <div className='coverfordetailblog'>
                      <img className='detailimageblog' src={detailexplain} alt='explain'></img>

          </div>
         </div>
    </div>
    <div className='main_detail_blog'>
        <div className='social_icons'>
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
  <path d="M16 21C18.7614 21 21 18.7614 21 16C21 13.2386 18.7614 11 16 11C13.2386 11 11 13.2386 11 16C11 18.7614 13.2386 21 16 21Z" stroke="#374147" stroke-width="2" stroke-miterlimit="10"/>
  <path d="M21.5 4.5H10.5C7.18629 4.5 4.5 7.18629 4.5 10.5V21.5C4.5 24.8137 7.18629 27.5 10.5 27.5H21.5C24.8137 27.5 27.5 24.8137 27.5 21.5V10.5C27.5 7.18629 24.8137 4.5 21.5 4.5Z" stroke="#374147" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M22.5 11C23.3284 11 24 10.3284 24 9.5C24 8.67157 23.3284 8 22.5 8C21.6716 8 21 8.67157 21 9.5C21 10.3284 21.6716 11 22.5 11Z" fill="#374147"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
  <path d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z" stroke="#374147" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M21 11H19C18.6056 10.9984 18.2147 11.0748 17.85 11.225C17.4853 11.3752 17.1539 11.5961 16.875 11.875C16.5961 12.1539 16.3752 12.4853 16.225 12.85C16.0748 13.2147 15.9984 13.6056 16 14V28" stroke="#374147" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M12 18H20" stroke="#374147" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
  <path d="M20 16L14 12V20L20 16Z" stroke="#374147" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M3 16C3 19.725 3.3875 21.9 3.675 23.025C3.75427 23.3302 3.90418 23.6126 4.11262 23.8493C4.32106 24.0859 4.58221 24.2703 4.875 24.3875C9.0625 25.9875 16 25.95 16 25.95C16 25.95 22.9375 25.9875 27.125 24.3875C27.4178 24.2703 27.6789 24.0859 27.8874 23.8493C28.0958 23.6126 28.2457 23.3302 28.325 23.025C28.6125 21.9 29 19.725 29 16C29 12.275 28.6125 10.1 28.325 8.975C28.2457 8.66975 28.0958 8.38741 27.8874 8.15074C27.6789 7.91407 27.4178 7.7297 27.125 7.6125C22.9375 6.0125 16 6.05 16 6.05C16 6.05 9.0625 6.0125 4.875 7.6125C4.58221 7.7297 4.32106 7.91407 4.11262 8.15074C3.90418 8.38741 3.75427 8.66975 3.675 8.975C3.3875 10.1 3 12.275 3 16Z" stroke="#374147" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
  <path d="M6 18C7.0515 17.9967 8.09327 18.2014 9.06537 18.6023C10.0375 19.0031 10.9207 19.5923 11.6642 20.3358C12.4077 21.0794 12.9969 21.9626 13.3978 22.9347C13.7986 23.9068 14.0033 24.9485 14 26" stroke="#374147" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M6 12C9.71303 12 13.274 13.475 15.8995 16.1005C18.525 18.726 20 22.287 20 26" stroke="#374147" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M6 6.00004C8.62689 5.9951 11.2287 6.51063 13.6553 7.51686C16.0818 8.52309 18.285 10.0001 20.1375 11.8625C22 13.7151 23.4769 15.9182 24.4832 18.3448C25.4894 20.7713 26.0049 23.3731 26 26" stroke="#374147" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M6.5 27C7.32843 27 8 26.3284 8 25.5C8 24.6716 7.32843 24 6.5 24C5.67157 24 5 24.6716 5 25.5C5 26.3284 5.67157 27 6.5 27Z" fill="#374147"/>
</svg>
        </div>
        <div className='blog_text_side'>
        <div className='small_blue_date' style={{paddingTop:"0px"}}>05 Mai  2024</div>
<div className='blog_explain_title'>
Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor
</div>
<div className='main_text_blog'>
    <p>
    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
    </p>
    <p>
    It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).    </p>
    <p>
    Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.    </p>
    <p>
    The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.    </p>
    <p>
    There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.   </p>
</div>
<div className='blog_explain_title'>
Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor
</div>
<div className='main_text_blog'>
    <p>
    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
    </p>
    <p>
    It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).    </p>
    
</div>
        </div>
    </div>
    <BlogCards/>
<Blogfooter/>
    </>
  )
}

export default BlogDetail
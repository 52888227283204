import React from 'react'
import "./mobile.css"
import chemelon from "./images/Chamelon.png"
function Mobilechemeleon() {
  return (
    <div className='Mobilechemeleon'>

        <div className='textarea_mobile'>
            <span className='smalltitle_blue'>
                Product
            </span>
            <div className='larger_title'>
            Chameleon carrier
            </div>
            <p className='mobiledesc_chemelon'>
Unmasking Chameleon Carriers: Discover companies that strategically close and reopen to avoid regulations. Our advanced data capabilities and proprietary solutions at Freight Insurance Data locate these elusive entities, ensuring compliance and enabling profitable underwriting through the latest technologies.            </p>
            <div className='mobile_image_section'>
                <img src={chemelon} alt='image'></img>
            </div>
        </div>
    </div>
  )
}

export default Mobilechemeleon

import React from 'react'
import headerimg from '../images/aboutus/headerimg.png'
import { motion,useAnimation  } from 'framer-motion'; // Import motion from Framer Motion
function AboutusHero() {
    return (
        <>
            <div className="containerwidth ">
                <div className="flexitems " style={{paddingTop:"139px"}}>
                <motion.div
                className='aboutheading'
                initial={{ x: -100, opacity: 0 }} // Initial position (left side of the screen)
                animate={{ x: 0, opacity: 1 }} // Animation to move to the center and become visible
                transition={{ duration: 0.5, delay: 0.2 }} // Animation duration and delay
            >
                    
                        
                        <h4 className='aboutusheading'>
                        <p className='aboutmini'>About us</p>
                        100+ years of insurance experience
                        </h4>
                        <p className='aboutuspera'>
                        At Freight Insurance Data, we boast over 100 years of combined experience in trucking insurance. Our mission is to enhance the safety of commercial auto and trucking risks through our proprietary data solutions.
                        </p>

                    </motion.div>
                    <motion.div
                className="aboutimage"
                initial={{ x: '100%', opacity: 0 }} // Initial position (right side of the screen)
                animate={{ x: 0, opacity: 1 }} // Animation to move to the center and become visible
                transition={{ duration: 0.5, delay: 0.8 }} // Animation duration and delay
            >

                        <img src={headerimg} alt="" className='' />
                    </motion.div>
                </div>
            </div>

        </>
    )
}

export default AboutusHero
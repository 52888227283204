import React from 'react'
import { motion,useAnimation  } from 'framer-motion'; // Import motion from Framer Motion
import aboutimage from "../images/aboutimage.png"
function AboutUs() {
  return (
   <>
    <div className='About-us-section'>
   <motion.div
                className='aboutheading'
                initial={{ x: -100, opacity: 0 }} // Initial position (left side of the screen)
                animate={{ x: 0, opacity: 1 }} // Animation to move to the center and become visible
                transition={{ duration: 0.5, delay: 0.2 }} // Animation duration and delay
            >
      <div className='contentofabout'>
        <span className='aboutus'>
          About us
        </span>
        <div className='texthead'>
        Crafted by trucking insurance agents for agent triumph.
        </div>
      </div>
      <p className='paracontent'>
      Freight Insurance Data was born out of necessity. Our retail agency lacked a suitable solution in the market - no existing software provided comprehensive, user-friendly data for our agents. We revolutionized insurance for the future, empowering agents to excel in this complex transportation market.
      </p>
     
    </motion.div>
    <motion.div
                className="aboutimage"
                initial={{ x: '100%', opacity: 0 }} // Initial position (right side of the screen)
                animate={{ x: 0, opacity: 1 }} // Animation to move to the center and become visible
                transition={{ duration: 0.5, delay: 0.8 }} // Animation duration and delay
            >
                <img src={aboutimage} alt=' about image'></img>
            </motion.div>
   </div>
  

  
   <div className='About-us-section'>
            <motion.div
                className='bluecontent'
                initial={{ x: -100, opacity: 0, scale: 0 }} // Initial position and scale (left side of the screen and small)
                animate={{ x: 0, opacity: 1, scale: 1 }} // Animation to move to the center, become visible, and grow in size
                transition={{ duration: 0.5, delay: 0.2 }} // Animation duration and delay
            >
                <div className='numberhead'>1</div>
                <p className='numberexplain'>Source</p>
            </motion.div>
            <motion.div
                className='bluecontent'
                initial={{ y: 100, opacity: 0, scale: 0 }} // Initial position and scale (below the screen and small)
                animate={{ y: 0, opacity: 1, scale: 1 }} // Animation to move to the center, become visible, and grow in size
                transition={{ duration: 0.5, delay: 0.4 }} // Animation duration and delay
            >
                <div className='numberhead'>1,000+</div>
                <p className='numberexplain'>Successful Customers</p>
            </motion.div>
            <motion.div
                className='bluecontent'
                initial={{ x: 100, opacity: 0, scale: 0 }} // Initial position and scale (right side of the screen and small)
                animate={{ x: 0, opacity: 1, scale: 1 }} // Animation to move to the center, become visible, and grow in size
                transition={{ duration: 0.5, delay: 0.6 }} // Animation duration and delay
            >
                <div className='numberhead'>3M+</div>
                <p className='numberexplain'>USDOT Safety Data Records</p>
            </motion.div>
        </div>
    
   </>
  )
}

export default AboutUs
import React from 'react'

function Standerdlorem() {
    return (
        <>
            <div className="containerwidth" >
                <div className="coverofparagrap containerwidth flexitems" >
                    <div className="headingside" >
                        <p className='standerheding'>
                        Grow Your Agency with Our Comprehensive Commercial Auto Data
                        </p>
                    </div>
                    <div className="parasta">
                        <p className='standerpera'>
                        Comprising former trucking insurance agents, DOT safety officers, and mega-carrier safety managers, our team brings diverse expertise.
                        </p>
                        <p className='standerpera'>
                        Leveraging our proprietary system features, we've crafted tools poised to elevate your agency's performance to unprecedented levels, empowering you to excel in the competitive landscape
                        </p>
                    </div>
                </div>


                    <div className=" secoundhead ">
                        <div>
                        <p className='standerdheading2'>10x Growth Starts Today</p>
                        <p className='standerdpera2'>
                        Today's the day to transform your insurance agency with unparalleled data, enabling you to write more premium than ever before. Don't miss out on our revolutionary features at Freight Insurance Data—essential for any agency handling commercial auto insurance.
                        </p>
                        </div>
                </div>
            </div>
        </>
    )
}

export default Standerdlorem
import React from 'react'
import { motion,useAnimation  } from 'framer-motion'; // Import motion from Framer Motion
import CountUp from 'react-countup';
function Counter() {
  return (
   <>
    <div className='abovetoabout'>
   <div className='About-us-section for_about_page' style={{bottom:'0px'}}>
            <motion.div
                className='bluecontent'
                initial={{ x: -100, opacity: 0, scale: 0 }} // Initial position and scale (left side of the screen and small)
                animate={{ x: 0, opacity: 1, scale: 1 }} // Animation to move to the center, become visible, and grow in size
                transition={{ duration: 0.5, delay: 0.2 }} // Animation duration and delay
            >
                <div className='numberhead'>
                <CountUp start={0} end={1} duration={2} />

                </div>
                <p className='numberexplain'>Source</p>
            </motion.div>
            <motion.div
                className='bluecontent'
                initial={{ y: 100, opacity: 0, scale: 0 }} // Initial position and scale (below the screen and small)
                animate={{ y: 0, opacity: 1, scale: 1 }} // Animation to move to the center, become visible, and grow in size
                transition={{ duration: 0.5, delay: 0.4 }} // Animation duration and delay
            >
                <div className='numberhead'>                <CountUp start={0} end={1000} duration={2} />
                +</div>
                <p className='numberexplain'>Successful Customers</p>
            </motion.div>
            <motion.div
                className='bluecontent'
                initial={{ x: 100, opacity: 0, scale: 0 }} // Initial position and scale (right side of the screen and small)
                animate={{ x: 0, opacity: 1, scale: 1 }} // Animation to move to the center, become visible, and grow in size
                transition={{ duration: 0.5, delay: 0.6 }} // Animation duration and delay
            >
                <div className='numberhead'>                <CountUp start={0} end={3} duration={2} />
                M+</div>
                <p className='numberexplain'>USDOT Safety Data Records</p>
            </motion.div>
        </div>
        </div>
        </>
  )
}

export default Counter
import React from 'react'
import brand from "../../images/Brand.png"
function Blogfooter() {
  return (
    <div className='Blogfooter'>
        <div className='footernav'>
          <div className='foimage'>
            <img src={brand} alt='footer image'></img>
            </div>  
            <div className='alllinksfor_footer'>
                <span>Home</span>
                <span>Lastet posts</span>
                <span>Instagram</span>
                <span>Facebook</span>
                <span>Youtube</span>
            </div>
            <div className='frieightblog'>
            Freight Blog  © 2024
            </div>
        </div>
    </div>
  )
}

export default Blogfooter
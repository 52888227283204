import React from 'react'
import brand from "../../images/Brand.png"
import deep from "./images/Leadsimg.png"
function LeadsP() {
  return (
    <div className='stackcarrior'>
<div className='headerforstack'>
    <div className='maintitlecontent'>
    Leads Database
    </div>
    <p className='paraofstack'>
    Our proprietary lead data feature can help you close hundreds of additional leads annually. Filter transportation companies to match your criteria and appetite precisely. Access the industry's cutting-edge leads generator for an up-to-date list of motor carriers perfectly aligned with your operational, safety, and geographic preferences.  </p>
</div>
<div className='leftsidercarrier'>
<div className='Chamelonhome'>
        <div className='navcon'>
<img src={brand}  alt='brand logo'></img>
<div className='smallcont'>
    <div className='innersmall'></div>
    <div className='innersmall'></div>
    <div className='innersmall'></div>
    <div className='innersmall'></div>
</div>
        </div>
        <div className='mainaredash'>
            <div className='salesnav'>
                <div className='namesales'>
                    <div className='salesa'>
                     Sales |
                    </div>
                    <div className='chame'>
                    &nbsp;  Leads
                    </div>
                </div>
                <div className='mailerside'>
               
                <div className='whatsnew'>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
  <path d="M10 18C14.1421 18 17.5 14.6421 17.5 10.5C17.5 6.35786 14.1421 3 10 3C5.85786 3 2.5 6.35786 2.5 10.5C2.5 14.6421 5.85786 18 10 18Z" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M10 13C11.7259 13 13.125 11.6009 13.125 9.875C13.125 8.14911 11.7259 6.75 10 6.75C8.27411 6.75 6.875 8.14911 6.875 9.875C6.875 11.6009 8.27411 13 10 13Z" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M4.98438 16.0781C5.45462 15.1519 6.17216 14.374 7.05745 13.8306C7.94275 13.2872 8.96123 12.9995 10 12.9995C11.0388 12.9995 12.0572 13.2872 12.9425 13.8306C13.8278 14.374 14.5454 15.1519 15.0156 16.0781" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
<span>My account</span>
                </div>
            </div>
            </div>
   <img src={deep} alt='this image' width="100%"></img>
        </div>
    </div>
</div>

    </div>
  )
}

export default LeadsP
import React from 'react'
import ProductHero from '../../component/ProductHero'
import Navbar from '../../component/Navbar'
import StanderdProduct from '../../component/StanderdProduct'
import './style.css'
import SliderProduct from '../../component/SliderProduct'


function Product() {
  return (
    <div className='forproduct'>
    <div className='Herosection'>
        <Navbar/>
    <ProductHero/>
    {/* <SliderProduct/> */}
    <StanderdProduct/>

    </div>
    <div className="abovetofooter">

</div>
    </div>
  )
}

export default Product
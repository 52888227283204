import React from 'react'
import "./mobile.css"
import chemelon from "./images/Tracker.png"
function Propect() {
  return (
    <div className='Mobilechemeleon'>

        <div className='textarea_mobile'>
            <span className='smalltitle_blue'>
                Product
            </span>
            <div className='larger_title'>
            Propect tracker
            </div>
            <p className='mobiledesc_chemelon'>
Want to boost your new business premium sales by hundreds of thousands? Freight Insurance Data combines our deep dive feature with Prospect Tracker to maximize your sales potential. Easily track, follow up, and manage data all in one user-friendly platform. Say goodbye to missed sales opportunities due to disorganized lead data.            </p>
            <div className='mobile_image_section'>
                <img src={chemelon} alt='image'></img>
            </div>
        </div>
    </div>
  )
}

export default Propect

import React from 'react'
import headerimg from '../images/product/heroimg.png'
function ProductHero() {
  return (
    <>
    <div className="containerwidth2">
                <div className="headerproduct" style={{paddingTop:"139px",gap:"90px"}}>
                    <div className="  headerproductleft " >
                        <p className='aboutmini'>Products</p>
                        <h4 className='productheading'>
                        Hypergrowth Is  Just A Click Away
                        </h4>
                        <p className='productpera'>
                        Unlock new sales heights for your commercial auto insurance agency with our proprietary data and features. Tailored for agencies specializing in commercial auto, our platform offers sales, underwriting, and CRM tools designed to drive growth. Experience the difference with just a click, and elevate your agency's performance today.                        </p>

                        <button className='productbtn zoom-in'>Start for free</button>

                    </div>
                    <div className="headerproductright " >

                        <img src={headerimg} alt="" className='' width="100%"/>
                    </div>
                </div>
            </div>
    </>
  )
}

export default ProductHero
import React from 'react'
import firstblog from "./Images/Firstblog.png"
function Bloglist() {
  return (
    <div className='Bloglist'>
        <div className='ListCard'>
<div className='list_left'>
<span className='blog_section_title'>Other articles</span>
<div className='coveroflist'>


<div className='listfirstblog'>
    <img className='listimage' src={firstblog} alt='firstblog'></img>
    <div className='explainerydiv'>
    <div className='small_blue_date' style={{paddingTop:"0px"}}>05 Mai  2024</div>
<div className='bloglist_title'>
Domine os dados como as grandes empresas
</div>
<p className='paraoflist'>
Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip,
</p>
    </div>
</div>
<div className='listfirstblog'>
    <img className='listimage' src={firstblog} alt='firstblog'></img>
    <div className='explainerydiv'>
    <div className='small_blue_date' style={{paddingTop:"0px"}}>05 Mai  2024</div>
<div className='bloglist_title'>
Domine os dados como as grandes empresas
</div>
<p className='paraoflist'>
Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip,
</p>
    </div>
</div>
<div className='listfirstblog'>
    <img className='listimage' src={firstblog} alt='firstblog'></img>
    <div className='explainerydiv'>
    <div className='small_blue_date' style={{paddingTop:"0px"}}>05 Mai  2024</div>
<div className='bloglist_title'>
Domine os dados como as grandes empresas
</div>
<p className='paraoflist'>
Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip,
</p>
    </div>
</div>
<div className='listfirstblog'>
    <img className='listimage' src={firstblog} alt='firstblog'></img>
    <div className='explainerydiv'>
    <div className='small_blue_date' style={{paddingTop:"0px"}}>05 Mai  2024</div>
<div className='bloglist_title'>
Domine os dados como as grandes empresas
</div>
<p className='paraoflist'>
Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip,
</p>
    </div>
</div>
<div className='listfirstblog'>
    <img className='listimage' src={firstblog} alt='firstblog'></img>
    <div className='explainerydiv'>
    <div className='small_blue_date' style={{paddingTop:"0px"}}>05 Mai  2024</div>
<div className='bloglist_title'>
Domine os dados como as grandes empresas
</div>
<p className='paraoflist'>
Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip,
</p>
    </div>
</div>
<div className='listfirstblog'>
    <img className='listimage' src={firstblog} alt='firstblog'></img>
    <div className='explainerydiv'>
    <div className='small_blue_date' style={{paddingTop:"0px"}}>05 Mai  2024</div>
<div className='bloglist_title'>
Domine os dados como as grandes empresas
</div>
<p className='paraoflist'>
Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip,
</p>
    </div>
</div>
<div className='listfirstblog'>
    <img className='listimage' src={firstblog} alt='firstblog'></img>
    <div className='explainerydiv'>
    <div className='small_blue_date' style={{paddingTop:"0px"}}>05 Mai  2024</div>
<div className='bloglist_title'>
Domine os dados como as grandes empresas
</div>
<p className='paraoflist'>
Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip,
</p>
    </div>
</div>
</div>
</div>
<div className='rightside'>
<span className='blog_section_title'>Most read</span>
<div className='most_read' style={{marginTop:"40px"}}>
<div className='small_blue_date' style={{paddingTop:"0px"}}>05 Mai  2024</div>
<div className='bloglist_title'>
Domine os dados como as grandes empresas
</div>
<div className='paraofmost_read'>
Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididu...
</div>
</div>
<div className='most_read' >
<div className='small_blue_date' style={{paddingTop:"0px"}}>05 Mai  2024</div>
<div className='bloglist_title'>
Domine os dados como as grandes empresas
</div>
<div className='paraofmost_read'>
Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididu...
</div>
</div>
<div className='most_read' >
<div className='small_blue_date' style={{paddingTop:"0px"}}>05 Mai  2024</div>
<div className='bloglist_title'>
Domine os dados como as grandes empresas
</div>
<div className='paraofmost_read'>
Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididu...
</div>
</div>
<div className='most_read' >
<div className='small_blue_date' style={{paddingTop:"0px"}}>05 Mai  2024</div>
<div className='bloglist_title'>
Domine os dados como as grandes empresas
</div>
<div className='paraofmost_read'>
Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididu...
</div>
</div>

</div>
        </div>
    </div>
  )
}

export default Bloglist
import React from 'react'
import cardfirst from "./Images/firstCardBlog.png"
import cardfirst1 from "./Images/firstCard40.png"
import cardfirst2 from "./Images/firstcard23.png"
import cardfirst3 from "./Images/first31.png"
function BlogCards() {
  return (
    <div className='cardsofblog'>
        <span className='blog_section_title'>Title section</span>
        <div className='cardscontainer'>
            <div className='induvidual_cards'>
                <img src={cardfirst} className='blogerimage'  alt='firstlogo'></img>
                <div className='small_blue_date'>05 Mai  2024</div>
<div className='induvidualblog_title'>
Lorem ipsum dolor sit amet, consectetur
</div>
<p className='para_of_induvidual_blog'>
Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip,
</p>
            </div>
            <div className='induvidual_cards'>
                <img src={cardfirst2} className='blogerimage'  alt='firstlogo'></img>
                <div className='small_blue_date'>05 Mai  2024</div>
<div className='induvidualblog_title'>
Lorem ipsum dolor sit amet, consectetur
</div>
<p className='para_of_induvidual_blog'>
Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip,
</p>
            </div>
            <div className='induvidual_cards'>
                <img src={cardfirst1} className='blogerimage'  alt='firstlogo'></img>
                <div className='small_blue_date'>05 Mai  2024</div>
<div className='induvidualblog_title'>
Lorem ipsum dolor sit amet, consectetur
</div>
<p className='para_of_induvidual_blog'>
Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip,
</p>
            </div>
            <div className='induvidual_cards'>
                <img src={cardfirst3} className='blogerimage'  alt='firstlogo'></img>
                <div className='small_blue_date'>05 Mai  2024</div>
<div className='induvidualblog_title'>
Lorem ipsum dolor sit amet, consectetur
</div>
<p className='para_of_induvidual_blog'>
Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip,
</p>
            </div>
        </div>
    </div>
  )
}

export default BlogCards
import React from 'react'
import brand from "../../images/Brand.png"

function StackCamlion() {
  return (
    <div className='stackcarrior'>
<div className='headerforstack'>
    <div className='maintitlecontent'>
    Chameleon Carrier
    </div>
    <p className='paraofstack'>
    Unmasking Chameleon Carriers: Discover companies that strategically close and reopen to avoid regulations. Our advanced data capabilities and proprietary solutions at Freight Insurance Data locate these elusive entities, ensuring compliance and enabling profitable underwriting through the latest technologies.    </p>
</div>
<div className='leftsidercarrier'>
<div className='Chamelonhome'>
        <div className='navcon'>
<img src={brand}  alt='brand logo'></img>
<div className='smallcont'>
    <div className='innersmall'></div>
    <div className='innersmall'></div>
    <div className='innersmall'></div>
    <div className='innersmall'></div>
</div>
        </div>
        <div className='mainaredash'>
            <div className='salesnav'>
                <div className='namesales'>
                    <div className='salesa'>
                     Sales |
                    </div>
                    <div className='chame'>
                    &nbsp; Chameleon 
                    </div>
                </div>
                <div className='mailerside'>
               
                <div className='whatsnew'>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
  <path d="M4.39038 9.25C4.39038 8.51337 4.53547 7.78395 4.81737 7.10339C5.09926 6.42282 5.51245 5.80445 6.03333 5.28357C6.5542 4.76269 7.17258 4.34951 7.85314 4.06761C8.5337 3.78572 9.26312 3.64062 9.99975 3.64062C10.7364 3.64062 11.4658 3.78572 12.1464 4.06761C12.8269 4.34951 13.4453 4.76269 13.9662 5.28357C14.4871 5.80445 14.9002 6.42282 15.1821 7.10339C15.464 7.78395 15.6091 8.51337 15.6091 9.25V9.25C15.6091 12.0469 16.1951 13.6719 16.7107 14.5625C16.7655 14.6574 16.7943 14.7649 16.7944 14.8745C16.7945 14.984 16.7658 15.0916 16.7112 15.1865C16.6566 15.2815 16.5781 15.3604 16.4834 15.4154C16.3886 15.4705 16.2811 15.4996 16.1716 15.5H3.82788C3.71835 15.4996 3.61085 15.4705 3.51615 15.4154C3.42144 15.3604 3.34286 15.2815 3.28826 15.1865C3.23367 15.0916 3.20498 14.984 3.20508 14.8745C3.20518 14.7649 3.23405 14.6574 3.28881 14.5625C3.80444 13.6719 4.39038 12.0469 4.39038 9.25Z" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M7.5 15.5V16.125C7.5 16.788 7.76339 17.4239 8.23223 17.8928C8.70107 18.3616 9.33696 18.625 10 18.625C10.663 18.625 11.2989 18.3616 11.7678 17.8928C12.2366 17.4239 12.5 16.788 12.5 16.125V15.5" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M14.3281 2.375C15.6007 3.17829 16.6277 4.31615 17.2969 5.66406" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M2.70312 5.66406C3.37228 4.31615 4.39933 3.17829 5.67188 2.375" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
<span>What’s new?</span>
                </div>
            </div>
            </div>
          <div className='newsearch'>
            <div className='buttonforsearch'>
            New search
            </div>
            <div className='mailerside'>
                <div className='whatsnew'>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <path d="M6.71875 8.59375L10 11.875L13.2812 8.59375" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M10 3.125V11.875" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M16.875 11.875V16.25C16.875 16.4158 16.8092 16.5747 16.6919 16.6919C16.5747 16.8092 16.4158 16.875 16.25 16.875H3.75C3.58424 16.875 3.42527 16.8092 3.30806 16.6919C3.19085 16.5747 3.125 16.4158 3.125 16.25V11.875" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
                </div>
                <div className='forborder'>
                    
                </div>
                <div className='whatsnew'>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <path d="M5 6.25V3.125H15V6.25" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M15 11.875H5V17.1875H15V11.875Z" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M5 13.75H2.1875V7.5C2.1875 6.8125 2.79687 6.25 3.53906 6.25H16.4609C17.2031 6.25 17.8125 6.8125 17.8125 7.5V13.75H15" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M14.6875 10C15.2053 10 15.625 9.58027 15.625 9.0625C15.625 8.54473 15.2053 8.125 14.6875 8.125C14.1697 8.125 13.75 8.54473 13.75 9.0625C13.75 9.58027 14.1697 10 14.6875 10Z" fill="#374147"/>
</svg>
                </div>
            </div>
          </div>
          <div className='dotnumber'>
          USDOT Number: #2284379
          </div>
         <div className='selectors'>
               <div className='dashinforcormer'>
            <div className='titlesectiondash'>
            ABC Trucking LLC
            </div>
            <div className='ineroptions' style={{padding:"10px"}}>
            <div className='classification'>

               
<div className='sectiontoselect'  style={{backgroundColor:"rgba(47, 185, 94, 0.30)"}}>
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
  <path d="M13.5 4.5L6.5 11.5L3 8" stroke="#2FB95E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
Exempt for hire
</div>
<div className='sectiontoselect'>
Private (Property)
</div>
<div className='sectiontoselect'>
Exempt for hire
</div>
<div className='sectiontoselect'>
Migrant
</div>
</div>
            </div>
          </div>
          <div className='dashinforcormer'>
            <div className='titlesectiondash'>
            XYZ Trucking LLC
            </div>
            <div className='ineroptions' style={{padding:"10px"}}>
                <div className='classification'>

               
              <div className='sectiontoselect' style={{backgroundColor:"rgba(47, 185, 94, 0.30)"}}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
  <path d="M13.5 4.5L6.5 11.5L3 8" stroke="#2FB95E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
              Exempt for hire
              </div>
              <div className='sectiontoselect'>
              Private (Property)
              </div>
              <div className='sectiontoselect'>
              Exempt for hire
              </div>
              <div className='sectiontoselect'>
              Migrant
              </div>
              </div>
            </div>
          </div>
         </div>
       
        </div>
    </div>
</div>

    </div>
  )
}

export default StackCamlion
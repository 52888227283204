import { message, Select } from 'antd';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const { Option } = Select;

const states = [
  "Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado", "Connecticut", "Delaware", "Florida", 
  "Georgia", "Hawaii", "Idaho", "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky", "Louisiana", "Maine", 
  "Maryland", "Massachusetts", "Michigan", "Minnesota", "Mississippi", "Missouri", "Montana", "Nebraska", 
  "Nevada", "New Hampshire", "New Jersey", "New Mexico", "New York", "North Carolina", "North Dakota", "Ohio", 
  "Oklahoma", "Oregon", "Pennsylvania", "Rhode Island", "South Carolina", "South Dakota", "Tennessee", "Texas", 
  "Utah", "Vermont", "Virginia", "Washington", "West Virginia", "Wisconsin", "Wyoming"
];

const FormComponent = ({ handleform }) => {
  const [formData, setFormData] = useState({
    fullName: '',
    companyName: '',
    phoneNumber: '',
    email: '',
    password: '',
    confirmPassword: '',
    companyAddress: '',
    companyAddress2: '',
    companyCity: '',
    companyState: ''
  });

  const [errors, setErrors] = useState({});
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  useEffect(() => {
    const storedFormData = localStorage.getItem('formData');
    if (storedFormData) {
      setFormData(JSON.parse(storedFormData));
    }
  }, []);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
    setErrors({ ...errors, [id]: '' }); // Clear error when user starts typing
  };

  const handleStateChange = (value) => {
    setFormData({ ...formData, companyState: value });
    setErrors({ ...errors, companyState: '' });
  };

  const formatPhoneNumber = (value) => {
    const cleaned = ('' + value).replace(/\D/g, '').slice(0, 10); // Remove non-digits and limit to 10 digits
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{0,4})$/);
    if (match) {
      return `(${match[1]})-${match[2]}${match[3] ? '-' + match[3] : ''}`;
    }
    return value;
  };

  const handlePhoneChange = (e) => {
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    setFormData({ ...formData, phoneNumber: formattedPhoneNumber });
    setErrors({ ...errors, phoneNumber: '' }); // Clear error when user starts typing
  };

  const handleForm = (e) => {
    e.preventDefault();

    const newErrors = {};
    let hasError = false;

    Object.keys(formData).forEach((key) => {
      if (!formData[key] && key !== 'companyAddress2') {
        newErrors[key] = 'This field is required';
        hasError = true;
      }
    });

    if (formData.password !== formData.confirmPassword) {
      newErrors.confirmPassword = 'Passwords do not match';
      hasError = true;
    }

    if (formData.email && !/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Email is invalid';
      hasError = true;
    }

    if (formData.phoneNumber && !/^\(\d{3}\)-\d{3}-\d{4}$/.test(formData.phoneNumber)) {
      newErrors.phoneNumber = 'Phone number is invalid';
      hasError = true;
    }

    setErrors(newErrors);

    if (hasError) {
      message.error("Please fill all the fields correctly");
      return;
    }

    const fullNameParts = formData.fullName.trim().split(' ');
    const lastName = fullNameParts.pop(); // Get the last name
    const firstName = fullNameParts.join(' '); // Join the rest as the first name

    const submissionData = {
      ...formData,
      fullName: firstName,
      lastName: lastName // Save last name in local storage
    };

    localStorage.setItem('formData', JSON.stringify(submissionData));
    handleform();
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  const errorStyle = {
    borderColor: 'red',
    borderWidth: '2px'
  }
  return (
    <div className='form-wraper'>
      <form className="row g-3" onSubmit={handleForm}>
        <div className="col-md-6">
          <label htmlFor="fullName" className="form-label">Full Name</label>
          <input
            type="text"
            className="form-control"
            id="fullName"
            placeholder='Full Name'
            value={formData.fullName}
            onChange={handleChange}
            style={errors.fullName ? errorStyle : {}}
          />
          {errors.fullName && <div className="invalid-feedback">{errors.fullName}</div>}
        </div>
        <div className="col-md-6">
          <label htmlFor="companyName" className="form-label">Company Name</label>
          <input
            type="text"
            className="form-control"
            id="companyName"
            placeholder='Company Name'
            value={formData.companyName}
            onChange={handleChange}
            style={errors.companyName ? errorStyle : {}}
          />
          {errors.companyName && <div className="invalid-feedback">{errors.companyName}</div>}
        </div>
        <div className="col-md-6">
          <label htmlFor="phoneNumber" className="form-label">Phone Number</label>
          <input
            type="text"
            className="form-control"
            id="phoneNumber"
            placeholder='(XXX)-XXX-XXXX'
            value={formData.phoneNumber}
            onChange={handlePhoneChange}
            style={errors.phoneNumber ? errorStyle : {}}
          />
          {errors.phoneNumber && <div className="invalid-feedback">{errors.phoneNumber}</div>}
        </div>
        <div className="col-md-6">
          <label htmlFor="email" className="form-label">Email</label>
          <input
            type="text"
            className="form-control"
            id="email"
            placeholder='Email'
            value={formData.email}
            onChange={handleChange}
            style={errors.email ? errorStyle : {}}
          />
          {errors.email && <div className="invalid-feedback">{errors.email}</div>}
        </div>
        <div className="col-md-6">
          <label htmlFor="password" className="form-label">Password</label>
          <div className="input-group">
            <div className='forpasswortype'>
              <input
                type={passwordVisible ? "text" : "password"}
                id="password"
                placeholder='Password'
                value={formData.password}
                onChange={handleChange}
                style={errors.password ? errorStyle : {}}
              />
              <div className='mt-3 mx-3' style={{ cursor: "pointer" }} onClick={togglePasswordVisibility}
              >
                {passwordVisible ? (<>
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_4965_29830)">
                      {/* <path d="M1.66699 1.66699L18.3337 18.3337" stroke="#2C292A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> */}
                      <path d="M5.5943 5.60249C3.05432 7.32972 1.66699 10.0003 1.66699 10.0003C1.66699 10.0003 4.69729 15.8337 10.0003 15.8337C11.7089 15.8337 13.1815 15.2282 14.3929 14.4073M9.16699 4.21551C9.43816 4.18377 9.71599 4.16699 10.0003 4.16699C15.3033 4.16699 18.3337 10.0003 18.3337 10.0003C18.3337 10.0003 17.7572 11.1101 16.667 12.3616" stroke="#2C292A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M11.6667 11.8636C11.2243 12.2594 10.6403 12.5002 10 12.5002C8.61925 12.5002 7.5 11.3809 7.5 10.0002C7.5 9.31375 7.77661 8.692 8.22443 8.24023" stroke="#2C292A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </g>
                    <defs>
                      <clipPath id="clip0_4965_29830">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </>) : (<>

                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_4965_29830)">
                      <path d="M1.66699 1.66699L18.3337 18.3337" stroke="#2C292A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M5.5943 5.60249C3.05432 7.32972 1.66699 10.0003 1.66699 10.0003C1.66699 10.0003 4.69729 15.8337 10.0003 15.8337C11.7089 15.8337 13.1815 15.2282 14.3929 14.4073M9.16699 4.21551C9.43816 4.18377 9.71599 4.16699 10.0003 4.16699C15.3033 4.16699 18.3337 10.0003 18.3337 10.0003C18.3337 10.0003 17.7572 11.1101 16.667 12.3616" stroke="#2C292A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M11.6667 11.8636C11.2243 12.2594 10.6403 12.5002 10 12.5002C8.61925 12.5002 7.5 11.3809 7.5 10.0002C7.5 9.31375 7.77661 8.692 8.22443 8.24023" stroke="#2C292A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </g>
                    <defs>
                      <clipPath id="clip0_4965_29830">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>

                </>)}
              </div>
            </div>

          </div>
          {errors.password && <div className="invalid-feedback">{errors.password}</div>}
        </div>
        <div className="col-md-6 labler">
          <label htmlFor="confirmPassword" className="form-label labler">Confirm Password</label>
          <div className="input-group">
            <div className='forpasswortype'>
              <input
                type={confirmPasswordVisible ? "text" : "password"}
                id="confirmPassword"
                placeholder='Confirm Password'
                value={formData.confirmPassword}
                onChange={handleChange}

                style={errors.confirmPassword ? errorStyle : {}}
              />
              <div className='mt-3 mx-3' style={{ cursor: "pointer" }} onClick={toggleConfirmPasswordVisibility}
              >
                {confirmPasswordVisible ? (<>
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_4965_29830)">
                      {/* <path d="M1.66699 1.66699L18.3337 18.3337" stroke="#2C292A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> */}
                      <path d="M5.5943 5.60249C3.05432 7.32972 1.66699 10.0003 1.66699 10.0003C1.66699 10.0003 4.69729 15.8337 10.0003 15.8337C11.7089 15.8337 13.1815 15.2282 14.3929 14.4073M9.16699 4.21551C9.43816 4.18377 9.71599 4.16699 10.0003 4.16699C15.3033 4.16699 18.3337 10.0003 18.3337 10.0003C18.3337 10.0003 17.7572 11.1101 16.667 12.3616" stroke="#2C292A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M11.6667 11.8636C11.2243 12.2594 10.6403 12.5002 10 12.5002C8.61925 12.5002 7.5 11.3809 7.5 10.0002C7.5 9.31375 7.77661 8.692 8.22443 8.24023" stroke="#2C292A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </g>
                    <defs>
                      <clipPath id="clip0_4965_29830">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </>) : (<>

                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_4965_29830)">
                      <path d="M1.66699 1.66699L18.3337 18.3337" stroke="#2C292A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M5.5943 5.60249C3.05432 7.32972 1.66699 10.0003 1.66699 10.0003C1.66699 10.0003 4.69729 15.8337 10.0003 15.8337C11.7089 15.8337 13.1815 15.2282 14.3929 14.4073M9.16699 4.21551C9.43816 4.18377 9.71599 4.16699 10.0003 4.16699C15.3033 4.16699 18.3337 10.0003 18.3337 10.0003C18.3337 10.0003 17.7572 11.1101 16.667 12.3616" stroke="#2C292A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M11.6667 11.8636C11.2243 12.2594 10.6403 12.5002 10 12.5002C8.61925 12.5002 7.5 11.3809 7.5 10.0002C7.5 9.31375 7.77661 8.692 8.22443 8.24023" stroke="#2C292A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </g>
                    <defs>
                      <clipPath id="clip0_4965_29830">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>

                </>)}
              </div>

            </div>


          </div>
          {errors.confirmPassword && <div className="invalid-feedback">{errors.confirmPassword}</div>}
        </div>
        <div className="col-md-6 labler2">
          <label htmlFor="companyAddress" className="form-label">Company Address</label>
          <input
            type="text"
            className="form-control"
            id="companyAddress"
            placeholder='Company Address'
            value={formData.companyAddress}
            onChange={handleChange}
            style={errors.companyAddress ? errorStyle : {}}
          />
          {errors.companyAddress && <div className="invalid-feedback">{errors.companyAddress}</div>}
        </div>
        <div className="col-md-6">
          <label htmlFor="companyAddress2" className="form-label">Company Address 2 (Optional)</label>
          <input
            type="text"
            className="form-control"
            id="companyAddress2"
            placeholder='Company Address 2'
            value={formData.companyAddress2}
            onChange={handleChange}
            style={errors.companyAddress2 ? errorStyle : {}}
          />
          {errors.companyAddress2 && <div className="invalid-feedback">{errors.companyAddress2}</div>}
        </div>
        <div className="col-md-6">
          <label htmlFor="companyCity" className="form-label">Company City</label>
          <input
            type="text"
            className="form-control"
            id="companyCity"
            placeholder='Company City'
            value={formData.companyCity}
            onChange={handleChange}
            style={errors.companyCity ? errorStyle : {}}
          />
          {errors.companyCity && <div className="invalid-feedback">{errors.companyCity}</div>}
        </div>
        <div className="col-md-6">
          <label htmlFor="companyState" className="form-label">Company State</label>
          <Select
            id="companyState"
            placeholder="Select a state"
            value={formData.companyState}
            onChange={handleStateChange}
            style={errors.companyState ? { ...errorStyle, width: '100%',height:'55px' } : { width: '100%',height:'55px' }}
            showSearch
            optionFilterProp="children"
          >
            {states.map(state => (
              <Option key={state} value={state}>{state}</Option>
            ))}
          </Select>
          {errors.companyState && <div className="invalid-feedback">{errors.companyState}</div>}
        </div>
        <div className="col-12 text-end">
          <div className='d-flex justify-content-end gap-4 formbtn'>
            <button type="button" className="btn btn-secondry"><Link to='/'>Back</Link></button>
            <button type="submit" className="btn btn-secondry">Continue</button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default FormComponent;
